import { Router } from '@angular/router';
import { RouterService } from './shared/services/router.service';
import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';




import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { DevicesService } from './shared/services/devices.service';
import { LoginService } from './shared/services/login.service';
import {Capacitor} from '@capacitor/core';

// declare var FCMPlugin: any;
// declare var universalLinks: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  constructor(
    private zone: NgZone,
    private router: Router,
    private routerService: RouterService,
    private devicesService: DevicesService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {

    if(Capacitor.getPlatform() === 'android') {
      this.requestPermission().then();
      if (Capacitor.getPlatform() === 'ios') {
        this.requestPermission().then();
      }
      PushNotifications.requestPermissions().then(result => {
        console.log(result, 'request Permission to Push notification');
        if (result.receive === 'granted') {
          PushNotifications.register().then();
        } else {
          console.log('usuário não aceitou as permissões de notificação');
        }
      });
  
      PushNotifications.addListener('registration', (token: Token) => {
        console.log(token, 'registration token');
          this.loginService.setTokenPushNotification(token);
        }
      );
  
      PushNotifications.addListener('registrationError', err => {
        console.log('Registration error: ', err.error);
      });
    }
    const setStatusBarStyleDark = async () => {
      await StatusBar.setStyle({ style: Style.Dark });
    };
    document.addEventListener('deviceready', () => {
      console.log('deviceready');
      this.DeepLinkEvent();
    });

    document.addEventListener('resume', (eventData: any) => {
      this.DeepLinkEvent();
    }, false);

    document.addEventListener('pause', (eventData: any) => {
      this.DeepLinkEvent();
    }, false);
  }

  DeepLinkEvent() {
    // universalLinks.subscribe(null, (eventData: any) => {
    //   if (eventData && eventData.hash.includes("criar-senha")) {
    //     let params = eventData.hash.replace("/criar-senha/", "").split('/');
    //     if (params.length == 2) {
    //       this.zone.run(() => {
    //         this.router.navigate(['/criar-senha/', params[0], params[1]]);
    //       });
    //     }
    //   }
    // });
  }
  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    return await AppTrackingTransparency.requestPermission();
  }
}

