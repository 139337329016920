// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
};

export const API = {
  base: 'https://actuar-api.dev.actuar.biz',
  user_service: 'https://userservice-api.dev.actuar.biz',
  chat: 'https://chat-api.dev.actuar.biz',
  odata: 'https://odata.dev.actuar.biz',
  odata_receivable_service: 'https://receivableservice-api.dev.actuar.biz/Odata',
  treino: 'https://treino-api.dev.actuar.biz',
  afig: 'https://afig-api.dev.actuar.biz',
  acesso: 'https://acesso-api.dev.actuar.biz',
  paguela: 'https://paguelacore-api.dev.actuar.biz',
  files_api: 'https://filegroup-api.dev.actuar.biz',
  client_device_service: 'https://clientdeviceservice-api.dev.actuar.biz',
  receivable_service: 'https://receivableservice-api.dev.actuar.biz',
  graduation_service: 'https://graduationservice-api.dev.actuar.biz',
};


export const URLS = {
  contas: 'https://contas.actuar.com',
  treino: 'https://trei.no',
  afig: 'https://app.afig.actuar.com',
  actuar_web: 'https://app.actuar.com',
  base_blob_storage: 'https://storageactuar.blob.core.windows.net/objetos/avatar',
};

export const CONTAS = [{
    nome: 'Actuar',
    account: 'eb7689de-62b1-4e1b-98aa-86ee648bfe91',
    name: 'Actuar',
  },
  {
    nome: 'Trei.no',
    account: '77a6bd71-6836-49e7-9f1b-90403fbca069',
    name: 'Treino',
  },
  {
    nome: 'Afig',
    account: 'f8f293ac-1498-438a-9e60-11d1e8a36c0d',
    name: 'Afig'
  },
  {
    nome: 'Catraca para academia',
    account: '9546e583-6fb6-46bc-aa5f-e49f202d9397',
    name: 'Catracaparaacademia'
  },
  {
    nome: 'Pague lá',
    account: '44cc6127-5197-401d-a68d-3bc9a2f4fe3e',
    name: 'Paguela'
  },
];
