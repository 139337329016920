import {Component, inject, OnInit} from '@angular/core';
import {GraduacaoService} from '../../shared/services/graduacao.service';
import {GraduationInterface} from '../../shared/interfaces/graduation.interface';

@Component({
  selector: 'app-graduacoes',
  templateUrl: './graduacoes.component.html',
  styleUrls: ['./graduacoes.component.scss'],
})
export class GraduacoesComponent implements  OnInit {
  public graduacoes: any;
  public loading = false;
  private graduacaoService = inject(GraduacaoService);

  ngOnInit(): void {
    this.carregarMinhasGraduacoes();
  }

  private carregarMinhasGraduacoes() {
    this.loading = true;
    this.graduacaoService.obterTodasGraduacoesPorPersonId().subscribe(( data: GraduationInterface[] ) => {
     this.graduacoes = data ?? [];
     this.loading = false;
    });
  }
}
