import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { GraduationInterface } from '../interfaces/graduation.interface';

@Injectable()
export class GraduacaoService {

  private http = inject(HttpClient);


  public obterTodasGraduacoesPorPersonId(): Observable<GraduationInterface[]> {
    const userData = JSON.parse(localStorage.getItem('treino/userData'));
    return this.http.get
      <any>(`${API.graduation_service}/PersonActivityLevel/GetLastGraduationsByPerson?personId=${userData.UserTenantId}`, {
        withCredentials: true
      }
    );
  }
}
