import { BehaviorSubject } from 'rxjs';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DevicesService } from './../../shared/services/devices.service';
import { EventEmitterService } from './../../shared/services/event-emitter.service';
import { LoginService } from './../../shared/services/login.service';
import { NetworkService } from './../../shared/services/network.service';
import { TreinoService } from './../../shared/services/treino.service';
import { Exercicio, Ficha, LicencaTreino, Treino } from './../core.interfaces';
import { Router } from '../../../../node_modules/@angular/router';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import {StorageService} from "../../shared/services/storage.service";
import * as moment from 'moment';


declare var $: any, FB: any;

@Component({
  selector: 'app-treino',
  templateUrl: './treino.component.html',
  styleUrls: ['./treino.component.scss']
})
export class TreinoComponent implements OnInit, OnDestroy {
  i: any = 0;
  ficha: Ficha;
  ficha$: any;
  isMobile: any;
  userData: any;
  treinoAtivo: Treino;
  obtendoFicha: boolean;
  treinoTrocado = false;
  conexaoInternet = true;
  msgNaoTemTreino: string;
  fichaFinalizada = false;
  quantidadeExercicios = 0;
  licencaTreino: LicencaTreino;
  msgCarregamento = 'Carregando treino...';
  editarCarga = {opened: false, exercicioData: {}};
  ajusteTreino: any;

  private _ficha = new BehaviorSubject({});
  public hoje = moment().startOf('day');
  isAlive = true;

  constructor(
    private zone: NgZone,
    private change: ChangeDetectorRef,
    private loginService: LoginService,
    private treinoService: TreinoService,
    private deviceService: DevicesService,
    private networkService: NetworkService,
    private router: Router,
    private swalService: SweetalertService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.checkConnection();

    this.loginService
      .getSessionData()
      .takeWhile(() => this.isAlive)
      .subscribe(response => {
        this.userData = response;

        if (response) {
          this.obterFicha(response);
        }
      });

    this.treinoService
      .obterLicencaAcademia()
      .takeWhile(() => this.isAlive)
      .subscribe(response => {
        if (response) {
          this.licencaTreino = response;
        }
      });

    this.treinoService
      .getAjuste()
      .takeWhile(() => this.isAlive)
      .subscribe(response => {
        if (response) {
          this.ajusteTreino = response;
        }
      });

    // REFRESH TREINOS
    EventEmitterService
      .get('refresh')
      .takeWhile(() => this.isAlive)
      .subscribe(() => {
        if (this.userData) {
          this.obterFicha(this.userData);
        }
      });

    EventEmitterService.get('atualizarCarga').subscribe((data) => {
      this.treinoAtivo.FichaTreinoItens.forEach((fichaTreino) => {
        if (fichaTreino.FichaTreinoItemId === data.FichaTreinoItemId) {
          fichaTreino.Carga = data.Carga;
        }
      });

      const fichaAtiva = JSON.parse(localStorage.getItem('treino/fichaAtiva'));

      if (fichaAtiva && fichaAtiva.Treinos) {
        if (fichaAtiva.Treinos && fichaAtiva.Treinos.length) {
          fichaAtiva.Treinos.forEach((treino) => {
            if (treino.FichaTreinoId === data.FichaTreinoId) {
              if (treino && treino.FichaTreinoItens && treino.FichaTreinoItens.length) {
                treino.FichaTreinoItens.forEach((fichaTreino) => {
                  if (fichaTreino.FichaTreinoItemId === data.FichaTreinoItemId) {
                    fichaTreino.Carga = data.Carga;
                  }
                });
              }
            }
          });
        }
      }
      this.storageService.set('fichaAtiva', fichaAtiva);
      EventEmitterService.get('atualizarCardExercicio').emit();
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
    this._ficha.unsubscribe();
  }

  sliderEditarCarga(event): void {
    this.editarCarga = event;
    const fileChooser = document.getElementById('carga-chooser');

    if (!fileChooser) {return; }

    if (fileChooser) {
      const isOpen = fileChooser.classList.value.indexOf('slideUp') > -1;
      if (isOpen) {
        fileChooser.classList.remove('slideUp');
        fileChooser.classList.add('slideDown');
      } else {
        fileChooser.classList.remove('slideDown');
        fileChooser.classList.add('slideUp');
      }
    }
  }

  obterFicha(cliente): void {
    this.obtendoFicha = true;
    this.msgCarregamento = 'Carregando...';

    if (this.ficha$) {
      this.ficha$.unsubscribe();
    }

    this.ficha$ = this.treinoService
      .obterFichaAtiva(cliente.UserTenantId)
      .takeWhile(() => this.isAlive)
      .filter((res: any) => {
        if (res.carregando) {
          this.obtendoFicha = true;
          return false;
        }

        return true;
      })
      .subscribe(response => {
        this._ficha.next(response.fichaAtiva);
        this.treinoAtivo = null;
        this.msgCarregamento = null;
        this.msgNaoTemTreino = null;
        this.ficha = response ? response.fichaAtiva : {};

        if (!this.ficha || !this.ficha.FichaId) {
          this.msgNaoTemTreino = 'Você ainda não possui treinos';
        } else if (this.ficha.Treinos) {
          this.treinoAtivo = this.ficha.Treinos.find(item => item.FichaTreinoId === this.ficha.TreinoDoDiaId)
          ? this.ficha.Treinos.find(item => item.FichaTreinoId === this.ficha.TreinoDoDiaId)
          : {};
          this.ficha.Treinos.sort((a, b): any => (a.LetraTreino < b.LetraTreino) ? -1 : (a.LetraTreino > b.LetraTreino) ? 1 : 0);

          this.treinoAtivo.FichaTreinoItens.forEach((val, i) => {
            if (val.TipoExercicio !== 'A' && !val.Carga) {
              val.Carga = '0';
            }
            val.SeriesConcluidas = 0;
          });
        }

        this.obtendoFicha = false;
      }, error => {
        console.error(error);
        this.obtendoFicha = false;
        this.msgNaoTemTreino = 'Ocorreu um erro ao carregar o treino';
      });
  }

  // Verifica se há conexão ativa
  checkConnection(): void {
    this.networkService.checkConnection()
      .takeWhile(() => this.isAlive)
      .subscribe((response: any) => {
        this.zone.run(() => {
          this.conexaoInternet = response;
        });
      });
  }

  completarExercicio(marcado, quantidade): void {
    marcado = marcado.marcado;
    quantidade = quantidade.quantidade;

    // Caso tenha trocado de ficha, zerar os exercicios
    if (
      this.quantidadeExercicios > this.treinoAtivo.FichaTreinoItens.length
      || typeof marcado === 'undefined'
      || typeof quantidade === 'undefined'
    ) {
      // console.log('parou ', this.quantidadeExercicios, this.treinoAtivo.FichaTreinoItens);
      this.treinoTrocado = true;
      this.fichaFinalizada = false;
      this.quantidadeExercicios = 0;
      return;
    }

    // Acrescentar manualmente ou automatico, no carregamento
    if (marcado === 'marcado' || marcado === 'marcado_automatico') {
      this.quantidadeExercicios += quantidade;
    } else if (marcado === '0') {
      this.quantidadeExercicios -= quantidade;
      if (this.quantidadeExercicios < 0) {
        this.quantidadeExercicios = 0;
      }
    }

    // Quando a quantidade igualar
    if (this.quantidadeExercicios === this.treinoAtivo.FichaTreinoItens.length) {

      // Caso tenha trocado de ficha, não mostrar
      if (this.treinoTrocado && marcado === 'marcado_automatico') {
        this.treinoTrocado = false;

        // fix para bug da primeira vez que entrar
      } else {
        if (this.i === 0 && marcado === 'marcado_automatico') {
          this.i++;
        } else {
          this.fichaFinalizada = true;
        }
      }
    }
  }

  // Retorna o exercício biset
  getBiset(set: number): Exercicio {
    if (set > 0 && this.treinoAtivo.FichaTreinoItens) {
      return this.treinoAtivo.FichaTreinoItens.filter(item => item.Set === set && item.SetOrdem === 2)[0];
    }
  }

  // Retorna o exercício triset
  getTriset(set: number): Exercicio {
    if (set > 0 && this.treinoAtivo.FichaTreinoItens) {
      return this.treinoAtivo.FichaTreinoItens.filter(item => item.Set === set && item.SetOrdem === 3)[0];
    }
  }

  salvarEstado(event) {
    if (event) {
      this.treinoService.salvarEstado(this.ficha);
    }
  }

  fecharPopUp(): void {
    this.fichaFinalizada = false;
    let stop = false;

    // Reiniciar series do treino selecionado
    // this.treinoAtivo.FichaTreinoItens.map((value, index) => {
    //   value.SeriesConcluidas = 0;
    //   this.treinoService.reiniciarSerie(value.FichaTreinoItemId)
    //     .takeWhile(() => this.isAlive)
    //     .subscribe();
    // });

    // Mudar treino para proxima ficha
    this.ficha.Treinos.forEach((value, index) => {
      if (this.treinoAtivo.LetraTreino === value.LetraTreino && !stop) {
        // Trocar de ficha e zerar a quantidade de exercícios
        if (this.ficha.Treinos[index + 1]) {
          this.quantidadeExercicios = 0;
          this.treinoAtivo = this.ficha.Treinos[index + 1];
          this.ficha.Treinos[index + 1].FichaTreinoItens.forEach((val, i) => {
            val.SeriesConcluidas = 0;
          });
          this.ficha.QtTreinosRealizados++;
          this._ficha.next(this.ficha)
        } else {
          this.quantidadeExercicios = 0;
          this.treinoAtivo = this.ficha.Treinos[0];
          this.ficha.Treinos[0].FichaTreinoItens.forEach((val, i) => {
            val.SeriesConcluidas = 0;
          });
          this.ficha.QtTreinosRealizados++;
          this._ficha.next(this.ficha);
        }

        stop = true;
        return;
      }
    });
  }

  public isOverdue(dueDate): boolean {
    return moment(dueDate).isBefore(this.hoje);
  }
}
