import { PlatformLocation } from '@angular/common';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { LoginService } from '../../shared/services/login.service';
import { TreinoService } from '../../shared/services/treino.service';
import { AcessoService } from '../../shared/services/acesso.service';
import { ReservasService } from '../../shared/services/reservas.service';
import { SweetalertService } from '../../shared/services/sweetalert.service';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';

import { SituacaoReservaAulaEnum } from '../../shared/enums/SituacaoReservaAula.enum';

import * as moment from 'moment';
import { Moment } from 'moment';
import { DevicesService } from '../../shared/services/devices.service';
import { TipoTempoAntesChekinSemReservaEnum } from '../../shared/enums/TipoTempoAntesChekinSemReserva.enum';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

declare var $: any;

@Component({
  selector: 'app-feed',
  templateUrl: './agendamento-aulas.component.html',
  styleUrls: ['./agendamento-aulas.component.scss'],
})
export class AgendamentoAulasComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('agendamento') agendamento: ElementRef;

  situacaoReservaAulaEnum = SituacaoReservaAulaEnum;

  datasDisponiveis = [];

  tipoSelecionado = '';

  prevData = 0;
  current_data = 0;
  data_swipe_width = 100;

  datas;
  swipeOptions;

  loading = true;

  isAlive = true;

  cards = [];
  pessoaId: string;

  turmas = [];
  reservas = [];

  reservasObtidas = false;

  ajustes = null;
  tipoCadastro = null;
  verParticipantesSelecionada: any;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private location: PlatformLocation,
    private treinoService: TreinoService,
    private sweetAlert: SweetalertService,
    private activatedRoute: ActivatedRoute,
    private reservaService: ReservasService,
    private sweetAlertService: SweetalertService,
    private errorHandlerService: ErrorHandlerService,
    private devicesService: DevicesService,
  ) { }

  ngOnInit(): void {
    this.location.onPopState(() => {
      this.router.navigate(['/painel/feed']).then();
    });

    EventEmitterService.get('refresh').takeWhile(() => this.isAlive).subscribe(() => {
      this.reservasObtidas = false;
      this.carregarDados(true);
    });

    this.obterUsuario();
  }

  ngAfterViewInit(): void {
    this.swipeOptions = {
      threshold: 75,
      cancelable: true,
      triggerOnTouchEnd: true,
      triggerOnTouchLeave: true,
      allowPageScroll: 'vertical',
      swipeStatus: this.swipeStatus.bind(this),
    };

    this.datas = $('#swipe-datas');
    this.datas.swipe(this.swipeOptions);

    this.datas.css('transform', 'translate(' + (this.agendamento.nativeElement.offsetWidth / 2 - 50) + 'px,0)');
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  obterAjustes(): void {
    this.treinoService.obterAjustes().subscribe((data) => {
      this.ajustes = data;
      this.obterTurmas();
    }, (err) => {
      this.errorHandlerService.error(err);
    });
  }

  obterUsuario(): void {
    this.loginService.getSessionData().takeWhile(() => this.isAlive).subscribe((usuario) => {
      if (!usuario || !usuario.UserTenantId) { return; }
      this.pessoaId = usuario.UserTenantId;
      this.tipoCadastro = usuario.TipoCadastro;

      this.carregarDados();
    });
  }

  carregarDados(recarregarpagina?: boolean): void {
    if (!recarregarpagina) {
      this.calcularDatasDisponiveis();
    }

    this.obterAjustes();
  }

  obterTurmas(): void {
    this.loading = true;

    const dataSelecionada = this.datasDisponiveis.find((data) => data.ativo);

    this.reservaService.obterTurmas(dataSelecionada.data).subscribe(response => {
      this.turmas = response.value;

      this.turmas.forEach((turma) => {
        turma.DataRealizacao = moment(turma.DataRealizacao).utc(false).toISOString();
      });

      this.ordenarTurmasPorDataRealizacao();
      this.obterReservasPorPessoaId();
    }, (err) => {
      this.errorHandlerService.error(err);
    });
  }

  obterReservasPorPessoaId(): void {
    if (this.reservasObtidas) {
      this.atualizaTela();
      return;
    }

    this.reservaService.obterReservasPorPessoaId(this.pessoaId).subscribe(data => {
      this.reservas = data.map((item) => {
        return {
          ReservaId: item.Reserva.ReservaId,
          Situacao: item.Reserva.Situacao,
          DataRealizacao: item.Turma.DataRealizacao,
          Descricao: item.Turma.Descricao,
          DuracaoAula: item.Turma.DuracaoAula,
          Local: item.Turma.Local,
          TurmaId: item.Turma.TurmaId,
          UrlAulaVirtual: item.Turma.UrlAulaVirtual,
          Vagas: item.Turma.Vagas,
        }
      });

      this.atualizaTela();
      this.reservasObtidas = true;
    }, (err) => {
      console.log(err);
      this.atualizaTela();
    });
  }

  ordenarTurmasPorDataRealizacao(): void {
    this.turmas = this.turmas.sort((a, b) => {
      return <any>new Date(a['DataRealizacao']) - <any>new Date(b['DataRealizacao']);
    });
  }

  calcularDatasDisponiveis(): void {
    const dataBase = moment();
    const quantidadeDiasDisponiveis = 90;

    this.datasDisponiveis = [];

    for (let i = 0; i < quantidadeDiasDisponiveis; i = i + 1) {
      this.datasDisponiveis.push({
        ativo: i === 0 ? true : false,
        data: moment(dataBase),
        dia: moment(dataBase).format('DD'),
        mes: moment(dataBase).locale('pt-br').format('MMM'),
      });


      dataBase.add(1, 'days');
    }
  }

  atualizaTela(): void {
    this.cards = [];
    this.tipoSelecionado = '';

    const hoje = moment().utc(true).toISOString();
    this.turmas.forEach(turma => {
      const reserva = this.obterReservaPorTurmaId(turma.TurmaId);
      const dataRealizacao = moment(turma.DataRealizacao).toDate().toUTCString();

      this.cards.push({
        TurmaId: turma.TurmaId,
        Reserva: reserva || null,
        Local: turma.Local || null,
        Titulo: turma.Titulo || null,
        DataRealizacao: dataRealizacao,
        Descricao: turma.Descricao || null,
        DuracaoMinutos: turma.DuracaoMinutos,
        Observacao: turma.Observacao,
        Situacao: reserva ? reserva.Situacao : null,
        UrlAulaVirtual: this.obterUrlComProtocolo(turma.UrlAulaVirtual),
        Vagas: turma.VagasDisponiveis >= 0 ? turma.VagasDisponiveis : turma.Vagas >= 0 ? turma.Vagas : 0,
        Finalizada: moment(hoje).utc(false).isAfter(moment(dataRealizacao).utc(false)),
        DataRealizacaoFinal: moment(dataRealizacao).utc(false).add(turma.DuracaoMinutos, 'minutes').toDate().toUTCString(),
      });
    });
    this.loading = false;
    this.realizarAgendamentoPorTurmaId();
  }

  obterUrlComProtocolo(url: string): string | null {
    if (!url) { return null; }
    return url.includes('http') ? url : 'http://' + url;
  }

  realizarAgendamentoPorTurmaId(): void {
    if (!this.activatedRoute.snapshot.params.aulaId) {
      return
    }

    const turma: object = this.turmas.find(x => x.TurmaId === this.activatedRoute.snapshot.params.aulaId);
    const reserva: object = this.reservas.filter(x => x.TurmaId === this.activatedRoute.snapshot.params.aulaId).find(x => x.Situacao === 0);

    turma ?
      !reserva ?
        this.agendarReserva(turma)
        : this.fazerCheckin(reserva)
      : (
        this.sweetAlert.error('Check-in não disponível para esta aula').subscribe(),
        this.router.navigate(['/painel/aulas']).then()
      );
  }

  obterReservaPorTurmaId(turmaId: string): any {
    return this.reservas.find(r => r.TurmaId === turmaId && r.Situacao !== SituacaoReservaAulaEnum.Cancelado);
  }

  swipeStatus(event, phase, direction, distance): void {
    if (phase === 'move' && (direction === 'left' || direction === 'right')) {
      const duration = 0;
      let prevSelecionado = 0;

      if (direction === 'left') {
        prevSelecionado = Math.round((this.data_swipe_width * this.current_data + distance) / 100);
        this.scrollImages((this.data_swipe_width * this.current_data - (this.agendamento.nativeElement.offsetWidth / 2 - 50)) + distance, duration);
      } else if (direction === 'right') {
        prevSelecionado = Math.round((this.data_swipe_width * this.current_data - distance) / 100);
        this.scrollImages((this.data_swipe_width * this.current_data - (this.agendamento.nativeElement.offsetWidth / 2 - 50)) - distance, duration);
      }

      this.prevData = prevSelecionado;
      this.selecionarDataPeloIndice(this.prevData);
    } else if (phase === 'end') {
      if (direction === 'right') {
        this.current_data = (Math.round((this.data_swipe_width * this.current_data - distance) / 100) >= 0) ? Math.round((this.data_swipe_width * this.current_data - distance) / 100) : 0;
        this.prevData = this.current_data;
        this.scrollImages(this.data_swipe_width * this.current_data - (this.agendamento.nativeElement.offsetWidth / 2 - 50), 500);
      } else if (direction === 'left') {
        this.current_data = (Math.round((this.data_swipe_width * this.current_data + distance) / 100) < this.datasDisponiveis.length) ? Math.round((this.data_swipe_width * this.current_data + distance) / 100) : this.datasDisponiveis.length - 1;
        this.prevData = this.current_data;
        this.scrollImages(this.data_swipe_width * this.current_data - (this.agendamento.nativeElement.offsetWidth / 2 - 50), 500);
      }

      this.selecionarDataPeloIndice(this.prevData);
      this.obterTurmas();
    } else {
      if (this.prevData === -1) {
        this.prevData = 0;
      }

      if (this.prevData > (this.datasDisponiveis.length - 1)) {
        this.prevData = this.datasDisponiveis.length - 1;
      }

      this.current_data = this.prevData;

      if (direction) {
        this.scrollImages(this.data_swipe_width * this.prevData - (this.agendamento.nativeElement.offsetWidth / 2 - 50), 500);

        this.selecionarDataPeloIndice(this.prevData);
        this.obterTurmas();
      }
    }
  }

  selecionarDataPeloIndice(indice: number): void {
    if (indice < 0) { indice = 0; }
    if (indice > this.datasDisponiveis.length - 1) { indice = this.datasDisponiveis.length - 1 }

    for (let i = 0; i < this.datasDisponiveis.length; i++) {
      this.datasDisponiveis[i].ativo = false;
    }

    this.datasDisponiveis[indice].ativo = true;
  }

  selecionarData(data, index): void {
    this.prevData = index;

    for (let i = 0; i < this.datasDisponiveis.length; i++) {
      this.datasDisponiveis[i].ativo = false;
    }

    data.ativo = true;

    this.scrollImages(this.data_swipe_width * index - (this.agendamento.nativeElement.offsetWidth / 2 - 50), 500);
    this.obterTurmas();
  }

  scrollImages(distance, duration): void {
    this.datas.css('transition-duration', (duration / 1000).toFixed(1) + 's');
    const value = (distance < 0 ? '' : '-') + Math.abs(distance).toString();
    this.datas.css('transform', 'translate(' + value + 'px,0)');
  }

  agendarReserva(card): void {
    if (this.ajustes && this.ajustes.ChekinSemReserva && this.ajustes.TempoAntesChekinSemReserva) {
      const dataRealizacao = card.DataRealizacao;
      const agora = moment().utc(true).toISOString();

      let diferencaTempo = null;

      if (this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Hora) {
        diferencaTempo = moment(dataRealizacao).diff(moment(agora), 'minutes') / 60;
      }

      if (this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Minuto) {
        diferencaTempo = moment(dataRealizacao).diff(moment(agora), 'minutes');
      }

      if (diferencaTempo > this.ajustes.TempoAntesChekinSemReserva) {
        this.sweetAlertService.alert('Ops!', `Só é possível fazer o check ${this.ajustes.TempoAntesChekinSemReserva} ${this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Hora ? 'horas' : 'minutos'} antes do início da aula.`).subscribe();
        return;
      }
    }

    const hoje = moment().utc(true).toISOString();

    if (this.ajustes && this.ajustes.QuantidadeReservaPorAluno) {
      let quantidadeReservas = 0;

      this.reservas.forEach((r) => {
        if (r && r.Situacao === this.situacaoReservaAulaEnum.Cancelado) { return; }

        const dataRealizacaoReserva = r && r ? moment(r.DataRealizacao).toDate().toUTCString() : null;
        const finalizada = moment(hoje).utc(false).isAfter(moment(dataRealizacaoReserva).utc(false));

        if (!finalizada) {
          quantidadeReservas++;
        }
      });

      if (quantidadeReservas >= this.ajustes.QuantidadeReservaPorAluno) {
        this.sweetAlertService.alert('Ops!', 'Só é possível ter ' + this.ajustes.QuantidadeReservaPorAluno + ' reservas ativas por aluno.').subscribe();
        return;
      }
    }

    if (!card || !card.TurmaId) {
      return; }
    if (card.agendando) {
      return; }

    const reserva = this.reservas.find(r => r.TurmaId === card.TurmaId);

    if (reserva) {
      card.agendando = true;
      this.reservaService.alterarSituacaoReserva(reserva.ReservaId, SituacaoReservaAulaEnum.Reservado).subscribe((data) => {
        card.Reserva = reserva;
        card.Situacao = this.ajustes && this.ajustes && this.ajustes.ChekinSemReserva ? SituacaoReservaAulaEnum.Confirmado : SituacaoReservaAulaEnum.Reservado;

        if (this.reservas && this.reservas.length) {
          this.reservas.forEach((r) => {
            if (r && r.TurmaId === card.TurmaId) {
              r.Situacao = this.ajustes && this.ajustes && this.ajustes.ChekinSemReserva ? SituacaoReservaAulaEnum.Confirmado : SituacaoReservaAulaEnum.Reservado;
            }
          });
        }

        card.agendando = false;

        if (this.ajustes && this.ajustes && this.ajustes.ChekinSemReserva) {
          this.sweetAlert.success('Check-in realizado com sucesso.').subscribe();
        } else {
          this.sweetAlert.success('Agendamento realizado com sucesso!').subscribe();
        }
      }, (err) => {
        card.agendando = false;
        this.errorHandlerService.error(err);
      });

      return;
    }
    card.agendando = true;

    this.sweetAlert.notificacao('Aguarde', 'Em alguns instantes seu agendamento será realizado', true).subscribe();
    this.reservaService.adicionarReserva(this.pessoaId, card.TurmaId, this.tipoCadastro).subscribe((data) => {
        if (!data) {
          this.sweetAlert.success('Agendamento realizado com sucesso!').subscribe();
        }

        this.reservas.push(data);
        card.Reserva = this.obterReservaPorTurmaId(card.TurmaId);
        card.Situacao = this.ajustes && this.ajustes && this.ajustes.ChekinSemReserva ? SituacaoReservaAulaEnum.Confirmado : SituacaoReservaAulaEnum.Reservado;

        if (this.reservas && this.reservas.length) {
          this.reservas.forEach((_reserva) => {
            if (_reserva && _reserva.TurmaId === card.TurmaId) {
              _reserva.Situacao = this.ajustes && this.ajustes && this.ajustes.ChekinSemReserva ? SituacaoReservaAulaEnum.Confirmado : SituacaoReservaAulaEnum.Reservado;
            }
          });
        }

        card.agendando = false;

        if (this.ajustes && this.ajustes.ChekinSemReserva) {
          this.sweetAlert.success('Check-in realizado com sucesso.').subscribe();
        }
      }, (err) => {
        card.agendando = false;
        if (err?.error?.Reserva[0]) {
          return this.sweetAlert.error(err.error.Reserva[0]).subscribe();
        }
        this.errorHandlerService.error(err);
      });
  }

  fazerCheckin(card): void {
    let reserva;

    if (card) {
      reserva = this.reservas.find((r) => r.ReservaId === card.Reserva.ReservaId);
    }

    if (this.ajustes?.TempoAntesChekinSemReserva && card?.DataRealizacao) {
      const dataRealizacao = card.DataRealizacao;
      const agora = moment().utc(true).toISOString();

      let diferencaTempo = null;

      if (this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Hora) {
        diferencaTempo = moment(dataRealizacao).diff(moment(agora), 'minutes') / 60;
      }

      if (this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Minuto) {
        diferencaTempo = moment(dataRealizacao).diff(moment(agora), 'minutes');
      }

      if (diferencaTempo > this.ajustes.TempoAntesChekinSemReserva) {
        this.sweetAlertService.alert('Ops!', `Só é possível fazer o check ${this.ajustes.TempoAntesChekinSemReserva} ${this.ajustes.TipoTempoAntesChekinSemReserva === TipoTempoAntesChekinSemReservaEnum.Hora ? 'horas' : 'minutos'} antes do início da aula.`).subscribe();
        return;
      }

    }

    if (reserva && reserva.Situacao === SituacaoReservaAulaEnum.Reservado) {
      card.confirmando = true;
      this.sweetAlert.notificacao('Aguarde', 'Em alguns instantes seu check-in será realizado', true).subscribe();

      this.reservaService.fazerCheckin(reserva.ReservaId).subscribe(() => {
        card.Situacao = SituacaoReservaAulaEnum.Confirmado;
        card.confirmando = false;
        this.sweetAlert.success('Check-in realizado com sucesso.').subscribe();

        if (this.reservas && this.reservas.length) {
          this.reservas.forEach((_reserva) => {
            if (_reserva && _reserva.TurmaId === card.TurmaId) {
              _reserva.Situacao = SituacaoReservaAulaEnum.Confirmado;
            }
          });
        }
      }, (err) => {
        card.confirmando = false;
        this.errorHandlerService.error(err);
      });
    }
  }

  cancelarReserva(card): void {
    if (!card || !card.Reserva || !card.Reserva.ReservaId) { return; }

    this.sweetAlert.confirm('', 'Deseja cancelar o agendamento desta aula?').subscribe((resp) => {

      if (!resp) {
        return;
      }

      this.sweetAlert.notificacao('Aguarde', 'Em alguns instantes seu cancelamento será realizado', true).subscribe();
      this.reservaService.cancelar(card.Reserva.ReservaId).subscribe(() => {
        card.Situacao = SituacaoReservaAulaEnum.Cancelado;
        this.sweetAlert.success('Agendamento cancelado com sucesso!').subscribe();

        if (this.reservas && this.reservas.length) {
          this.reservas.forEach((reserva) => {
            if (reserva && reserva.TurmaId === card.TurmaId) {
              reserva.Situacao = SituacaoReservaAulaEnum.Cancelado;
            }
          });
        }
      }, (err) => {
        this.sweetAlert.error(err?.error?.Reserva[0] ?? 'Não foi possível cancelar o agendamento.').subscribe();
        this.loading = false;
        this.errorHandlerService.error(err);
      });
    });
  }

  abrirCard(card: any): void {
    card.cardOpened = !card.cardOpened;

    if (!card.cardOpened) { return; }
    if (!card || !card.TurmaId) { return; }
    if (card.Local && card.Professores) { return; }

    card.isLoading = true;

    this.reservaService.obterTurmasDetalhadasPorId(card.TurmaId).subscribe((data) => {
      const professores = data.Professores.map(professor => professor.NomeCompleto);

      card.Local = data.Local;
      card.Professores = professores.join(', ');

      card.isLoading = false;
    }, (err) => {
      card.isLoading = false;
      card.cardOpened = false;

      this.errorHandlerService.error(err);
    });
  }

  obterAgendamentosAtivos(): any[] {
    const agendamentosAtivos = [];

    this.reservas.forEach((reserva) => {
      let finalizada = false;
      const turma = this.turmas.find((t) => t.TurmaId === reserva.TurmaId);

      const hoje = moment().utc(true).toISOString();

      if (turma) {
        const dataRealizacao = moment(turma.DataRealizacao);
        finalizada = moment(hoje).utc(false).isAfter(moment(dataRealizacao).utc(false));
      }

      if (reserva && reserva.Situacao !== SituacaoReservaAulaEnum.Cancelado && !finalizada) {
        agendamentosAtivos.push(turma);
      }
    });

    return agendamentosAtivos;
  }

  async abrirAulaVirtual(url: string) {
    if (Capacitor.getPlatform() === 'android' || 'ios') {
      await Browser.open({url});
    } else {
      window.open(url, '_blank');
    }
  }

  verParticipantes(aula) {
    this.router.navigate(['/painel/participantes/', aula.TurmaId]).then();
  }
}
