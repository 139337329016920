<ng-container *ngIf="!fichaFinalizada">
  <section class="content">
    <ng-container *ngIf="isOverdue(ficha?.DataVencimento)">
      <span class="badge amarelo" >
        Este treino já está vencido. Entre em contato com seu professor para mais informações.
      </span>
    </ng-container>
    <header class="bem-vindo-container">
      <p class="msg-bem-vindo">
        {{ userData?.Sex === 'F' ? 'Bem vinda' : 'Bem vindo'}}

        <strong>{{userData?.FirstName || '...'}}</strong>!

        <span *ngIf="treinoAtivo?.DiaSemana">
          Este é o seu treino de
          <strong>{{treinoAtivo.DiaSemana}}.</strong>
        </span>
      </p>
    </header>

    <div class="m-t-20 m-b-20" *ngIf="obtendoFicha">
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>

    <ng-container *ngIf="!obtendoFicha">

      <!-- BOTAO ALTERAR TREINO -->
      <button class="btn btn-menu m-b-30" type="button" mat-ripple (click)="menuTreino.open()" [disabled]="msgNaoTemTreino">
        <span [class.small]="!treinoAtivo?.LetraTreino">
          <span *ngIf="treinoAtivo">Treino</span>
          {{treinoAtivo?.LetraTreino || msgCarregamento || msgNaoTemTreino}}
          <span *ngIf="treinoAtivo?.Descricao"> | {{treinoAtivo?.Descricao}}</span>
        </span>
      </button>

      <!-- MENU ALTERAR TREINO -->
      <app-menu-treino
        #menuTreino
        [ficha]="ficha"
        [treinoAtivo]="treinoAtivo"
        (mudaTreinoAtivo)="treinoAtivo = $event; completarExercicio(999, $event)">
      </app-menu-treino>

      <!-- CARDS EXERCICIOS -->
      <div class="exercicios">
        <ng-container *ngFor="let exercicio of treinoAtivo?.FichaTreinoItens">
          <div class="card-container" *ngIf="!(exercicio.SetOrdem > 1)">
            <app-card-exercicio
              [exercicio]="exercicio"
              [exercicioBiset]="getBiset(exercicio.Set)"
              [exercicioTriset]="getTriset(exercicio.Set)"
              [editarCarga]="editarCarga"
              [ajusteTreino]="ajusteTreino"
              (cargaEditada)="sliderEditarCarga($event)"
              (salvarEstado)="salvarEstado($event)"
              (completarExercicio)="completarExercicio($event, $event)">
            </app-card-exercicio>
          </div>
        </ng-container>
        <!-- <app-card-ficha-info [ficha]="ficha" class="small"></app-card-ficha-info> -->
      </div>


      <!-- OBSERVAÇÕES E PATOLOGIAS -->
      <app-card-ficha-info></app-card-ficha-info>
      <div class="cronometro-popup" [routerLink]="['/painel/timers-escolha']">
        <i class="material-icons">
          <!--
            browsers antigos ( <= android 4.4 ) não entendem pelo nome do icone e precisam do código
            este icone é o rss_feed
           -->
           <!-- &#xE0E5; -->
           &#xE425;
        </i>
      </div>
    </ng-container>
  </section>
</ng-container>
<app-editar-carga [hidden]="!editarCarga.opened" [carga]="editarCarga.exercicioData"></app-editar-carga>

<app-share-treino *ngIf="fichaFinalizada" (fecharPopUp)="fecharPopUp()"></app-share-treino>
