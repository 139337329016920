<div class="body">
  <h3 class="sub-title">Minhas graduações</h3>
  <div class="cards" *ngIf="graduacoes">
    <div class="card" *ngFor="let graduacao of graduacoes">
      <span class="modalidade">{{graduacao.ActivityName}}</span>
      <div class="container-info">
        <span>Professor: <span class="professor">{{ graduacao.TeacherName }}</span></span>
        <div class="container-graduacao">
          <div class="cor" [style.background-color]="graduacao.Color"></div>
          <span>{{graduacao.LevelName}} {{ graduacao.SublevelName ? '- ' : ''}}{{graduacao.SublevelName}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="align-center" *ngIf="!graduacoes">

  </div>

  <div *ngIf="graduacoes && graduacoes.length === 0">
    <article class="align-center">
      <span>Não há registros a serem exibidos.</span>
    </article>
  </div>

  <div *ngIf="loading">
    <article class="align-center">
      <mat-progress-spinner mode="indeterminate" diameter="50" strokeWidth="2"></mat-progress-spinner>
    </article>
  </div>
</div>
