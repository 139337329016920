import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

var success = function(message: string) {};
var failure = function(message: string) {};

declare let ClarityPlugin: any;
if (typeof ClarityPlugin !== "undefined") {
  ClarityPlugin.initialize("oqag7u1rq4", success, failure, {isIonic: true});
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
